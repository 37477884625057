/*----------  Transition Mixins  ----------*/

@mixin transition($value: all 0.3s ease-in-out, $bfv: true)
{
	-webkit-transition: $value;
	-moz-transition: $value;
	-ms-transition: $value;
	-o-transition: $value;
	transition: $value;

	@if $bfv == true
	{
		@include backface-visibility(hidden);
	}
}