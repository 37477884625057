/*----------  Font Variables  ----------*/

$font-primary				: 'Roboto', sans-serif;
$font-secondary				: 'Montserrat', serif;
// $sub-font-01				: sans-serif;
// $sub-font-02				: sans-serif;

/*----------  Icon Fonts  ----------*/
$font-fontawesome			: 'Font Awesome 5 Free';

/*----------  Color Variables  ----------*/

/*----------  Bootstrap's Defaults  ----------*/

$brand-primary				: #007bff;
$brand-secondary			: #868e96;
$brand-success				: #28a745;
$brand-info					: #17a2b8;
$brand-warning				: #ffc107;
$brand-danger				: #dc3545;
$brand-light				: #f8f9fa;
$brand-dark					: #343a40;

/*-----  Default Variables  -----*/

$white-color				: #fff !default;
$black-color				: #000 !default;

/*-----  Unsorted Variables  -----*/

// $color-01					: #;
// $color-02					: #;

/*-----  Brands Variables  -----*/

/* Brand Primary */
// $brand-primary-01			: #;
// $brand-primary-02			: #;

/* Brand Info */
// $brand-info-01				: #;
// $brand-info-02				: #;

/* Brand Warning */
// $brand-warning-01			: #;
// $brand-warning-02			: #;

/* Brand Success */
// $brand-success-01			: #;
// $brand-success-02			: #;

/* Brand Danger */
// $brand-danger-01				: #;
// $brand-danger-02				: #;

/* Brand Gray */
// $brand-gray-01				: #;
// $brand-gray-02				: #;

/*----------  Font Weight Variables  ----------*/

$weight-hairline 			: 100;
$weight-light	 			: 300;
$weight-regular 			: 400;
$weight-medium				: 500;
$weight-semibold 			: 600;
$weight-bold	 			: 700;
$weight-extrabold	 		: 900;

/*----------  Font Size Variables  ----------*/

$font-base					: rem-calc(16);					// 16px
$font-xs					: $font-base - rem-calc(4);		// 12px
$font-sm					: $font-base - rem-calc(2);		// 14px
$font-md					: $font-base + rem-calc(2);		// 18px
$font-lg					: $font-base + rem-calc(4);		// 20px
$font-xl					: $font-base + rem-calc(24);	// 40px
$font-xxl					: $font-base + rem-calc(44);	// 60px

/*----------  Grid  ----------*/

$grid-space-default			: 30;
$col-name					: 'cs-col';

/*----------  Containers  ----------*/

$container-default-gutter: 40;
$container-default-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px
);